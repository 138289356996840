.App {
  text-align: center;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Header {
  background-color: #051433;
  height: 3vh;
  color: white;
  padding: 17px 17px;
}

.Text-left {
  display: flex;
  flex-direction: row;
  justify-content: initial;
}

.Text-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Footer {
  background-color: #051433;
  height: 5vh;
  width: 100%;
  color: white;
  bottom: 0;
  position: absolute;
}

.Font-small {
  font-size: 90%;
}

.Full-width {
  width: 100%;
}

.Full-height {
  height: 100%;
}

.Login-form {
  margin: 0px 30px;
}

#Logo-tinbot {
  margin-top: 10vh;
}

#Login-title {
  margin-top: 25px;
  font-size: 125%;
  text-align: center;
  font-weight: 600;
}

#list-title {
  margin-top: 25px;
  font-size: 125%;
  font-weight: 600;
}

.Buttons {
  margin-top: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 50px !important;
  color: white !important;
}

.Primary-color {
  background-color: #f91933 !important;
}

.Secondary-color {
  background-color: #051433 !important;
}

.Reset-modal {
  padding: 15px;
}

.Tinbot-card {
  margin: 30px 30px;
}

.Tinbot-item {
  font-weight: 600;
  padding: 15px 15px;
}

.Content {
  height: 88vh;
}

.Card-image {
  padding: 20px 20px 0px 20px;
}

.Paper-card {
  padding: 5px;
}

#list-textarea-grid-container {
  display: flex;
  /* height: 90%; */
  /* width: 100%; */
  flex-direction: column;
  /* flex-wrap: nowrap !important; */
  /* flex-wrap: nowrap; */
  text-align: center;
  /* overflow-y: auto !important; */
  align-items: center;
  padding-top: 64px; /* actionsbar.height - header.height */
}

.grid-content {
  width: 100%;
}

.grid-content.selected {
  border: blue 1px solid;
}

.local-command-item {
  width: 100%;
}

.textarea-local-command-item {
  padding: 12.5px 10px !important;
}

.favorite-action {
  background: url("assets/favorite.png") no-repeat 98% center,
    linear-gradient(to right, rgb(253, 204, 135), rgb(253, 146, 155));
}

.non-favorite-action {
  background: linear-gradient(to right, white, lightblue);
}

/* ///////////////////////// */

#main-grid-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

#main-grid-container-item {
  display: flex;
  flex: 1;
  overflow: auto;
}

#main-grid-container-item-content {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
}

#login-container {
  display: flex;
}

#login-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.login-title {
  text-align: center;
  font-weight: 700;
}

.login-grid-container-item {
  align-self: stretch;
}

.button-centralized {
  text-align: center;
}

.image-centralized {
  text-align: center;
}

#button-install-pwa {
  color: white;
}

.group-multiple-components {
  height: 100%;
  width: 100%;
}

#leaf-team-list-grid-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.leaf-team-list-item-button {
  display: flex !important;
  justify-content: space-between !important;
  text-align: left !important;
}

.leaf-team-online {
  color: green;
}

.leaf-team-offline {
  color: red;
}

.content-centralized {
  text-align: center;
}

#options-container {
  display: flex;
  height: 100%;
  width: 100%;
}

#options-grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ios-share-icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  vertical-align: bottom;
}

.ios-add-icon {
  vertical-align: bottom;
}
